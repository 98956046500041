<template>
<section class="blockElement pageNotFound">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="error-template p-4">
                    <h1>Oops!</h1>
                    <h2>404 Not Found</h2>
                    <p class="error-details">
                        Sorry, an error has occured, Requested page not found!
                    </p>
                    <div class="error-actions text-center mb-4">
                        <router-link to="/" class="grd_btn">Take Me Home</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
